import React from 'react'
export default function QrDemo({
    image
}) {
    return (
        <div className="h-auto w-full flex flex-col justify-around font-body items-center bg-white p-4 md:p-12">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center w-full py-5 space-y-6 md:space-y-0 md:space-x-6">
                {/* Heading Section */}
                <div className="w-full md:w-[45%]">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-black">
                        The Simplest Way for Guests to Pay
                    </h1>
                </div>
                {/* Description Section */}
                <div className="w-full md:w-[45%]">
                    <p className="font-medium text-lg md:text-xl lg:text-2xl text-gray-700">
                        The best experience for your guests: 10 seconds to pay, leave a tip,
                        leave a review, and get a receipt.
                    </p>
                </div>
            </div>

            {/* QR Code Section */}
            <div className="md:h-[80vh] w-full mt-8 rounded-3xl flex flex-col md:flex-row justify-between items-center bg-gray-200 py-8 md:px-10">
                <div className="w-full md:w-[50%] text-center md:text-left">
                    <h1 className="text-3xl md:text-4xl lg:text-3xl font-semibold text-black mb-4">
                        Test it by yourself!
                    </h1>
                    <p className="text-lg md:text-lg lg:text-lg text-gray-700">
                        Scan the QR code with your smartphone to access the demo.
                    </p>
                </div>
                <div className="h-[200px] w-[200px] mt-2 md:mt-0 md:h-full md:w-[550px] flex justify-center items-center rounded-3xl bg-logo">
                    <img src={image} alt="QR Code" className="h-[88%] w-[88%] object-contain" />
                </div>
            </div>

        </div>
    )
}

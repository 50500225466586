import React from 'react';
import Header from '../../component/Header';
import { image } from '../../assets/image';
import Footer from '../../component/Footer';

export default function Demo() {
    return (
        <div>
            <Header screen={true} />

            {/* Header Section */}
            <div className="flex flex-col font-body md:flex-row justify-between mt-12 items-start w-full px-4 md:px-10">
                {/* Form Section */}
                <div className="w-full md:w-[50%] flex flex-col space-y-5 p-4 md:p-10">
                    <h1 className="text-3xl md:text-4xl text-logo animate-blink-once">
                        Request a demo
                    </h1>
                    <h1 className="text-4xl md:text-7xl text-black animate-blink-once">
                        Get started now.
                    </h1>
                    {/* First Name and Last Name */}
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-x-4">
                        <input
                            type="text"
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                            placeholder="First Name"
                            aria-label="First Name"
                        />
                        <input
                            type="text"
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                            placeholder="Last Name"
                            aria-label="Last Name"
                        />
                    </div>
                    {/* Email */}
                    <input
                        type="email"
                        className="h-14 w-full bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Email*"
                        aria-label="Email"
                    />
                    {/* Phone */}
                    <input
                        type="text"
                        className="h-14 w-full bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="(201) 555-0123"
                        aria-label="Phone"
                    />
                    {/* Restaurant Name and POS */}
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-x-4">
                        <input
                            type="text"
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                            placeholder="Restaurant Name*"
                            aria-label="Restaurant Name"
                        />
                        <select
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-600 outline-none"
                            aria-label="POS System"
                        >
                            <option>Other POS</option>
                            <option>NCR-Aloha</option>
                            <option>LightSpeed K Series-iKentoo</option>
                            <option>LightSpeed U Series-Upserve</option>
                            <option>Toast</option>
                            <option>RES 3700</option>
                            <option>Square</option>
                            <option>Clover</option>
                            <option>Brink</option>
                            <option>FocusPOS</option>
                            <option>HarborTouch</option>
                            <option>Aldelo</option>
                            <option>Positouch</option>
                            <option>Revel System</option>
                            <option>Squirrel 10.20</option>
                            <option>TouchBistro</option>
                        </select>
                    </div>
                    {/* Address */}
                    <input
                        type="text"
                        className="h-14 w-full bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Address*"
                        aria-label="Address"
                    />
                    {/* City and Zip Code */}
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-x-4">
                        <input
                            type="text"
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                            placeholder="City"
                            aria-label="City"
                        />
                        <input
                            type="text"
                            className="h-14 w-full md:w-[45%] bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                            placeholder="Zip Code*"
                            aria-label="Zip Code"
                        />
                    </div>
                    {/* Additional Details */}
                    <textarea
                        className="w-full h-28 bg-white border-[1px] border-gray-600 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Any details to add"
                        aria-label="Additional Details"
                    ></textarea>
                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="bg-black text-white font-semibold h-[50px] w-[150px] rounded-full hover:scale-110 transition-transform duration-200"
                    >
                        Get In Touch
                    </button>
                </div>

                {/* Right Section */}
                <div className="w-full md:w-[50%] flex flex-col items-center md:items-start animate-blink-once p-4 md:p-10">
                    <img src={image.sample1} alt="Demo" className="w-full h-auto" />
                </div>
            </div>

            <Footer screen={true} />
        </div>
    );
}

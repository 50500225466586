import React from 'react';
import { useNavigate } from 'react-router-dom';
import { image } from '../assets/image';

export default function Footer({ screen }) {
    const navigate = useNavigate();

    return (
        <footer className={`w-full h-[70vh] md:h-[60vh]   flex flex-col font-body justify-center items-center ${screen ? "bg-white" : "bg-black"}`}>
            <hr className='w-[88%] h-[1px]' />
            <h1 className={`font-heading text-4xl sm:text-5xl md:text-6xl py-4 font-bold text-logo text-center`}>
                Scan it, eat it, love it,
            </h1>
            <div className='w-full md:w-[75%] flex flex-wrap items-start space-y-2 justify-between h-[62%] mx-5 mt-10'>
                {/* Take Payments Section */}
                <section className='w-[45%] md:w-[20%] flex flex-col space-y-3 px-3'>
                    <h2 className={`${screen ? 'text-gray-600' : 'text-white'} text-base sm:text-lg md:text-base font-light`}>Take Payments</h2>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Order and Pay</a>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Pay at Table</a>
                </section>

                {/* Resources Section */}
                <section className='w-[45%] md:w-[20%] flex flex-col space-y-3 px-3'>
                    <h2 className={`${screen ? 'text-gray-600' : 'text-white'} text-base sm:text-lg md:text-base font-light`}>Resources</h2>
                    <a href='/who-we-are' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Who we are</a>
                    <a href='/blogs' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Blog</a>
                    <a href='/carreers' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Careers</a>
                </section>

                {/* Contact Us Section */}
                <section className='w-[45%] md:w-[20%] flex flex-col space-y-3 px-3'>
                    <h2 className={`${screen ? 'text-gray-600' : 'text-white'} text-base sm:text-lg md:text-base font-light`}>Contact us</h2>
                    <a href='/get-started' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Get Started</a>
                    <a href='/partners' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Partners</a>
                    <a href='/help' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Help &#8599;</a>
                </section>

                {/* Socials Section */}
                <section className='w-[45%] md:w-[20%] flex flex-col space-y-3 px-3'>
                    <h2 className={`${screen ? 'text-gray-600' : 'text-white'} text-base sm:text-lg md:text-base font-light`}>Socials</h2>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Instagram</a>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Facebook</a>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>LinkedIn</a>
                    <a href='/order-and-pay' className={`${screen ? 'text-gray-800' : 'text-white'} font-light text-sm md:text-base`}>Twitter</a>
                </section>
            </div>

            <div className='w-full md:w-[75%] flex flex-wrap justify-between h-[62%] mx-5 mt-10'>
                <h1
                    onClick={() => {
                        navigate('/');
                    }}
                    className={`${screen ? 'text-gray-600' : 'text-white'} flex items-center cursor-pointer text-2xl sm:text-3xl font-light font-body`}
                >
                    <img src={image.logo} className="h-[40px] sm:h-[50px] pr-3" alt="logo" />
                    Saturday
                </h1>
            </div>
        </footer>
    );
}

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialsCarousel = () => {
    return (
        <Carousel
            autoPlay
            infiniteLoop
            transitionTime={1000}
            className="w-full"
            showThumbs={false}
            showIndicators={false}
        >
            {/* Slide 1 */}
            <div className="flex flex-col md:flex-row justify-center font-body items-center space-y-8 md:space-y-0 md:space-x-8 w-full p-4">
                {/* Card 1 */}
                <div className="h-[300px] w-full md:w-[45%] bg-gray-100 rounded-lg flex flex-col justify-around p-6">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-black">
                        Faster Payments, Happier Customers
                    </h1>
                    <p className="text-sm sm:text-base md:text-lg text-black text-left mt-4">
                        "Saturday has revolutionized how our restaurant handles payments.
                        Customers no longer wait to settle bills, leading to better
                        satisfaction and quicker table turnover. It’s been a win-win!"
                    </p>
                    <p className="text-gray-700 text-sm text-left mt-2">
                        Aman Verma, Restaurant Owner
                    </p>
                </div>
                {/* Card 2 */}
                <div className="h-[300px] w-full md:w-[45%] bg-gray-100 rounded-lg flex flex-col justify-around p-6">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-black">
                        Boost in Online Ratings
                    </h1>
                    <p className="text-sm sm:text-base md:text-lg text-black text-left mt-4">
                        "Saturday has directly led to an increase in Google Reviews for our
                        restaurant. The seamless payment experience makes customers happy to
                        leave positive feedback."
                    </p>
                    <p className="text-gray-700 text-sm text-left mt-2">
                        Priya Sharma, Manager
                    </p>
                </div>
            </div>

            {/* Slide 2 */}
            <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8 w-full p-4">
                {/* Card 1 */}
                <div className="h-[300px] w-full md:w-[45%] bg-gray-100 rounded-lg flex flex-col justify-around p-6">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-black">
                        Simplified Billing for Groups
                    </h1>
                    <p className="text-sm sm:text-base md:text-lg text-black text-left mt-4">
                        "Handling group payments is now seamless with Saturday. Customers can
                        pay individually without hassle, and it’s reflected instantly in our
                        system."
                    </p>
                    <p className="text-gray-700 text-sm text-left mt-2">
                        Ravi Gupta, Operations Head
                    </p>
                </div>
                {/* Card 2 */}
                <div className="h-[300px] w-full md:w-[45%] bg-gray-100 rounded-lg flex flex-col justify-around p-6">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-black">
                        Easier Staff Management
                    </h1>
                    <p className="text-sm sm:text-base md:text-lg text-black text-left mt-4">
                        "Saturday has made our team more efficient. The time saved on billing
                        allows staff to focus on customer service, improving satisfaction and
                        tips."
                    </p>
                    <p className="text-gray-700 text-sm text-left mt-2">
                        Meera Khanna, Team Lead
                    </p>
                </div>
            </div>
        </Carousel>
    );
};

export default TestimonialsCarousel;

import React from "react";
import { image } from "../../assets/image";

const Benefits = () => {
    const benefitsData = [
        {
            img: image.turnover,
            title: "More Turnover",
            description:
                "Increase basket size by 12% with digital menus available on demand to drive upsells and maximize reordering. Serve 50% more guests thanks to simpler operations.",
        },
        {
            img: image.staff,
            title: "More Time for your Staff",
            description:
                "Save up to 15 minutes per table so that your staff can focus on taking care of your guests.",
        },
        {
            img: image.tips,
            title: "More Tips",
            description:
                "Delight your employees with pre-selected tips that can amount to up to 10% more than their usual total.",
        },
        {
            img: image.reviews,
            title: "More Positive Google Reviews",
            description:
                "Get on top of your Google game with up to 2 times more reviews, highly encouraged at the end of the payment process.",
        },
        {
            img: image.guest,
            title: "Better Experience for your Guests",
            description:
                "No more waiting for your order. Customers know the status of their orders and estimated pick-up or delivery time. They can relax and enjoy their time from start to finish.",
        },
        {
            img: image.cost,
            title: "And Yes, Less Costs",
            description:
                "Optimize your labor costs thanks to mobile ordering.",
        },
    ];

    return (
        <div className="h-auto w-full flex flex-col justify-center items-center bg-black font-body text-white space-y-16 p-4 md:p-8 lg:p-12">
            {/* Heading Section */}
            <div className="flex flex-col md:flex-row w-full h-auto justify-around items-center pt-10 space-y-6 md:space-y-0">
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold w-full md:w-[45%] text-center md:text-left">
                    They don't wait for you. You don't wait for them.
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl w-full md:w-[45%] text-center md:text-left">
                    Less waiting time means more of everything else.
                </p>
            </div>

            {/* Statistics Section */}
            <div className="w-full h-auto flex flex-wrap justify-around items-center space-y-6 md:space-y-0 md:space-x-4">
                {["$70M", "2.5M", "500K Hours", "$9M"].map((stat, index) => (
                    <p
                        key={index}
                        className="text-4xl md:text-5xl font-semibold"
                    >
                        {stat}
                    </p>
                ))}
            </div>

            {/* Benefits Section */}
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
                {benefitsData.map((benefit, index) => (
                    <div
                        key={index}
                        className="flex flex-col justify-around items-start space-y-5 p-5 bg-gray-800 hover:bg-gray-900 transition-all duration-500 hover:shadow-lg hover:border-gray-700 rounded-lg"
                    >
                        <img
                            src={benefit.img}
                            alt={benefit.title}
                            className="hover:scale-105 transition-transform duration-500"
                        />
                        <h1 className="text-xl md:text-2xl">{benefit.title}</h1>
                        <p className="text-base md:text-lg text-gray-400">
                            {benefit.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Benefits;

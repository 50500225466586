import React from 'react'
import { IoChevronBack } from "react-icons/io5";
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CartCard from './component/CartCard'

export default function Cart() {
    const navigate = useNavigate()
    const cart = useSelector((state) => state.Reducers.cart)
    return (
        <div className="bg-white w-full h-[100vh] font-SUSE">
            <div className="flex justify-start top-0 sticky z-50 left-0  px-3 py-3  items-center w-full">
                <button
                    className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <IoChevronBack className='text-3xl' />
                </button>
                <h1
                    className={`text-black flex ml-3 items-center text-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
                >
                    Cart
                </h1>
            </div>
            <div className="flex flex-col items-center space-y-4 h-auto mt-2">
                {cart.map((item, index) => (
                    <CartCard key={index} item={item} />
                ))}
            </div>
            <div className="flex fixed bottom-[0px] font-body text-white text-lg w-full justify-center z-20 items-center">
                <button
                    onClick={() => navigate('/cart')}
                    className="bg-black flex justify-center lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
                >
                    Order Now
                </button>
            </div>
        </div>
    )
}

import React from 'react';
import { motion } from 'framer-motion';
import Header from '../../component/Header';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { image } from '../../assets/image';
import Benifits from '../constant/Benifits';
import ContactForm from '../constant/Contact';
import TestimonialsCarousel from '../constant/Testimonial';
import QrDemo from '../constant/QrDemo';
import Footer from '../../component/Footer';

export default function OrderPay() {
    const images = [image.sample1, image.sample2, image.sample3, image.sample4];

    return (
        <div>
            <Header screen={true} />
            <div className="min-h-screen w-screen bg-white flex flex-col-reverse font-body lg:flex-row justify-around items-center px-4 md:px-0">
                {/* Text Content */}
                <div className="text-black flex flex-col space-y-8 items-start md:px-10 justify-center w-full lg:w-1/2">
                    <h1 className=' text-5xl text-logo font-bold'>
                        Order & Pay
                    </h1>
                    <motion.h1
                        className="hidden md:flex lg:flex md:text-2xl lg:text-6xl font-semibold text-left lg:text-left font-body text-black mt-8 md:mt-10"
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 10,
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.5,
                        }}
                    >
                        All your orders - in <br /> and out
                    </motion.h1>
                    <p className='font-body font-normal text-base md:text-xl text-gray-600'>
                        Our mobile ordering solution allows your customers to order whenever they are ready with an integrated QR code.
                    </p>
                    <button
                        type="button"
                        className="relative h-10 w-40 sm:h-14 sm:w-48 lg:h-16 lg:w-56 font-medium rounded-full border border-black overflow-hidden group"
                    >
                        {/* Animated Background */}
                        <span className="absolute inset-0 bg-black transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100"></span>

                        {/* Button Content */}
                        <span className="relative z-10 text-black group-hover:text-white transition-colors duration-300">
                            Get Started
                        </span>
                    </button>
                </div>

                {/* Carousel Section */}
                <div className=" w-full lg:w-[600px] mt-24  lg:mt-0">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showIndicators={false}
                        showArrows={false}
                        interval={5000}
                    >
                        {images.map((item, index) => (
                            <img key={index} className="h-full w-full object-contain" src={item} alt={`carousel-image-${index}`} />
                        ))}
                    </Carousel>
                </div>
            </div>
            <TestimonialsCarousel />
            <Benifits />
            <QrDemo image={image.op} />
            <ContactForm />
            <Footer />
        </div>
    )
}

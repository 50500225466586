import axios from 'axios'
import { baseURL } from '../../helper/helper'

export const storeQRData = (rid, tid) => {
    return async (dispatch) => {
        try {
            localStorage.setItem('rid', rid)
            localStorage.setItem('tid', tid)
            dispatch({
                type: 'STORE_QR_DATA',
                payload: {
                    rid: rid,
                    tid: tid
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export const getRestaurant = (id, setLoading, setProgress) => {
    return async (dispatch) => {
        setLoading(true);
        setProgress(10); // Start with initial progress

        try {
            // Simulate progress update
            let progress = 10;
            const totalSteps = 5;

            // Update progress in intervals
            while (progress < totalSteps) {
                setProgress(Math.min(progress, 100)); // Set progress up to 100
                progress += 20; // Increment progress (adjust as needed)
                await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay between progress updates
            }

            // Make the actual API call
            const response = await axios.get(baseURL + `/get-restaurant-details`, {
                params: {
                    restaurant_id: id
                }
            });

            // Dispatch the response data
            dispatch({
                type: 'SET_RESTAURANT',
                payload: response.data
            });

            // Set progress to 100% when done
            setProgress(100);

        } catch (error) {
            console.error("Error fetching restaurant data", error);
            setProgress(0); // Reset progress on error
        } finally {
            setLoading(false); // Turn off loading state
        }
    }
}


export const getMenu = (id) => {
    return async (dispatch) => {
        await axios
            .get(baseURL + `/get-menu`, {
                params: {
                    restaurant_id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: 'SET_MENU',
                    payload: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

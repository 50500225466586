import React from "react";

const ContactForm = () => {
    return (
        <div className="w-full min-h-screen bg-black text-white flex flex-col font-body items-center p-5">
            {/* Header Section */}
            <div className="w-full flex flex-col lg:flex-row justify-center items-start space-y-10 lg:space-y-0 lg:space-x-10">
                <div className="w-full lg:w-[50%] text-center lg:text-left p-5">
                    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold animate-blink-once">
                        Empower Your Business With Saturday
                    </h1>
                </div>
                <div className="w-full lg:w-[50%] p-5">
                    <h1 className="text-xl sm:text-2xl font-semibold animate-blink-once text-center lg:text-left">
                        Ready to power your business with Saturday payments solution?
                        Fill out this form and one of our restaurant management experts
                        will be in touch.
                    </h1>
                </div>
            </div>

            {/* Form Section */}
            <div className="w-full lg:w-[50%] flex flex-col self-end space-y-5 p-5">
                {/* First Name and Last Name */}
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4  justify-between sm:space-y-0">
                    <input
                        type="text"
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="First Name"
                    />
                    <input
                        type="text"
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Last Name"
                    />
                </div>

                {/* Email */}
                <input
                    type="email"
                    className="h-14 w-full bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                    placeholder="Email*"
                />

                {/* Phone */}
                <input
                    type="text"
                    className="h-14 w-full bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                    placeholder="(201) 555-0123"
                />

                {/* Restaurant Name and POS */}
                <div className="flex flex-col sm:flex-row sm:space-x-4 justify-between  space-y-4 sm:space-y-0">
                    <input
                        type="text"
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Restaurant Name*"
                    />
                    <select
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-600 outline-none"
                    >
                        <option>Other POS</option>
                        <option>NCR-Aloha</option>
                        <option>LightSpeed K Series-iKentoo</option>
                        <option>LightSpeed U Series-Upserve</option>
                        <option>Toast</option>
                        <option>RES 3700</option>
                        <option>Square</option>
                        <option>Clover</option>
                        <option>Brink</option>
                        <option>FocusPOS</option>
                        <option>HarborTouch</option>
                        <option>Aldelo</option>
                        <option>Positouch</option>
                        <option>Revel System</option>
                        <option>Squirrel 10.20</option>
                        <option>TouchBistro</option>
                    </select>
                </div>

                {/* Address */}
                <input
                    type="text"
                    className="h-14 w-full bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                    placeholder="Address*"
                />

                {/* City and Zip Code */}
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 justify-between  sm:space-y-0">
                    <input
                        type="text"
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="City"
                    />
                    <input
                        type="text"
                        className="h-14 w-full sm:w-[45%] bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                        placeholder="Zip Code*"
                    />
                </div>

                {/* Additional Details */}
                <textarea
                    className="w-full h-28 bg-black border-[1px] border-gray-800 rounded-lg p-3 text-gray-500 outline-none"
                    placeholder="Any details to add"
                ></textarea>

                {/* Submit Button */}
                <button
                    type="button"
                    className="bg-white text-black font-semibold h-[50px] w-full sm:w-[150px] rounded-full hover:scale-110 transition-transform duration-200 mx-auto sm:mx-0"
                >
                    Get In Touch
                </button>
            </div>

            {/* Footer Section */}
            <div className="w-full h-auto bg-black text-white flex flex-col lg:flex-row justify-around items-center p-10">
                <div className="flex flex-col justify-center items-center space-y-5 text-center">
                    <h1 className="text-5xl sm:text-7xl font-bold">24/7</h1>
                    <p className="text-gray-400 text-lg sm:text-xl">Support Ready for you</p>
                </div>

                <div className="flex flex-col justify-center items-center space-y-5 text-center">
                    <h1 className="text-5xl sm:text-7xl font-bold">7 Days</h1>
                    <p className="text-gray-400 text-lg sm:text-xl">To receive your QR and be ready</p>
                </div>

                <div className="flex flex-col justify-center items-center space-y-5 text-center">
                    <h1 className="text-5xl sm:text-7xl font-bold">100%</h1>
                    <p className="text-gray-400 text-lg sm:text-xl">Walk out Coverage</p>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;

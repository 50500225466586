import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { HiMenuAlt3, HiOutlineX } from 'react-icons/hi';
import { image } from '../assets/image';
import { useNavigate } from 'react-router-dom';

export default function Header({ screen = false }) {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const handleDropdownToggle = () => {
        setDropdownOpen(prev => !prev);
    };
    const menuVariants = {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
        closed: {
            x: '100%',
            opacity: 0,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
    };

    return (
        <header className={`fixed top-0 left-0 w-full px-6 py-3 transition-all font-body ${screen ? "bg-white" : "bg-black "} flex items-center justify-between z-50`}>
            {/* Logo on the Left */}
            <h1
                onClick={() => navigate('/')}
                className={`${screen ? 'text-black' : 'text-white'} flex items-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
            >
                <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                Saturday
            </h1>

            {/* Hamburger Icon for Mobile */}
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`lg:hidden ${screen ? 'text-black' : 'text-white'} text-3xl focus:outline-none`}
            >
                <HiMenuAlt3 />
            </button>

            {/* Menu for Larger Screens */}
            <nav className="hidden lg:flex items-center space-x-8">
                {/* Dropdown Menu for Solutions */}
                <div
                    className="relative group"
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                >
                    {/* Dropdown Button */}
                    <button className={`text-lg cursor-pointer hover:text-logo ${screen ? 'text-black' : 'text-white'}`}>
                        Take Payments&#8620;
                    </button>

                    {/* Dropdown Content */}
                    <div
                        className={`absolute left-0 mt-2 w-[550px] bg-white flex justify-between items-start p-6 text-black rounded-lg shadow-lg transition-all duration-300 ${dropdownOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                    >
                        <div className="flex flex-col space-y-4">
                            {[
                                { title: 'Order & Pay', description: 'QR code payments', path: '/order-and-pay' },
                                { title: 'Pay at Table', description: 'Made for hospitality', path: '/pay-at-table' },
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => navigate(item.path)}
                                    className="p-4 hover:bg-gray-100 rounded-lg cursor-pointer transition"
                                >
                                    <h3 className="text-lg font-bold"> {index + 1}. {item.title}</h3>
                                    <p className="text-sm text-gray-500">{item.description}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col space-y-4 items-center w-1/3">
                            <img src={image.logo} alt='logo' className='w-[58%]' />
                            <h1 className='font-heading text-3xl font-bold'>Scan it,</h1>
                            <h1 className='font-heading text-3xl font-bold'>eat it,</h1>
                            <h1 className='font-heading text-3xl font-bold'>love it,</h1>
                        </div>
                    </div>
                </div>

                {/* Other Links */}
                {['Resources', 'Partners'].map((text, index) => (
                    <a
                        href="/"
                        key={index}
                        className={`text-lg ${screen ? 'text-black' : 'text-white'} hover:text-logo cursor-pointer`}
                    >
                        {text}&#8620;
                    </a>
                ))}
            </nav>

            {/* Login and Get Demo Buttons */}
            <div className="md:flex items-center space-x-5 hidden">
                <button
                    onClick={() => navigate('/merchant/login')}
                    className={`px-4 py-2 bg-transparent border font-body ${screen ? 'text-black border-black' : 'text-white border-white'} font-semibold rounded-full hover:bg-white hover:text-black transition`}
                >
                    Login
                </button>
                <button
                    onClick={() => navigate("/get-started")}
                    className={`px-4 py-2 ${screen ? 'text-white bg-black' : 'text-black bg-white'} font-semibold font-body rounded-full hover:bg-logo hover:text-white transition`}
                >
                    Get Demo
                </button>
            </div>

            {/* Full-Screen Sliding Menu for Mobile */}
            <motion.div
                initial="closed"
                animate={isOpen ? 'open' : 'closed'}
                variants={menuVariants}
                className={`fixed inset-0 h-screen p-4 ${screen ? 'bg-white' : 'bg-black'} backdrop-blur-lg flex flex-col items-center space-y-10 lg:hidden z-40`}
            >
                {/* Top Bar with Logo and Close Icon */}
                <div className="flex w-full justify-between px-2 items-center">
                    <img src={image.logo} className="h-[50px] pr-3" alt="logo" />
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className={`lg:hidden ${screen ? 'text-black' : 'text-white'} text-3xl focus:outline-none`}
                    >
                        <HiOutlineX />
                    </button>
                </div>

                {/* Links */}
                <div className="w-full flex-col flex space-y-3 px-2">
                    <div className="relative group">
                        {/* Dropdown Button */}
                        <button
                            onClick={handleDropdownToggle}
                            className={`text-lg cursor-pointer hover:text-logo ${screen ? 'text-black' : 'text-white'}`}
                        >
                            Take Payments&#8620;
                        </button>


                        <div className="flex flex-col">
                            {[
                                { title: 'Order & Pay', description: 'QR code payments', path: '/order-and-pay' },
                                { title: 'Pay at Table', description: 'Made for hospitality', path: '/pay-at-table' },
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => navigate(item.path)}
                                    className={`p-4 hover:bg-gray-100 ${screen ? "text-black" : "text-white"} rounded-lg cursor-pointer transition`}
                                >
                                    <h3 className="text-lg font-bold"> {index + 1}. {item.title}</h3>
                                    <p className="text-sm ">{item.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {['Resources', 'Partners'].map((text, index) => (
                        <a
                            href="/"
                            key={index}
                            className={`text-lg ${screen ? 'text-black' : 'text-white'} hover:text-logo cursor-pointer`}
                        >
                            {text}&#8620;
                        </a>
                    ))}
                </div>


                {/* Login and Get Demo Buttons for Mobile */}
                <div className="w-full flex flex-row items-center space-x-5 mt-8">
                    <button
                        onClick={() => navigate('/merchant/login')}
                        className={`w-3/12 px-4 py-3 bg-transparent border font-body ${screen ? 'text-black' : 'text-white'} font-semibold rounded-full hover:bg-white hover:text-black transition`}
                    >
                        Login
                    </button>
                    <button
                        onClick={() => navigate("/get-started")}
                        className={`w-3/6 px-4 py-3 ${screen ? 'text-white bg-black' : 'text-black bg-white'} font-semibold font-body rounded-full hover:bg-logo hover:text-white transition`}
                    >
                        Get Demo
                    </button>
                </div>

                <p className={`font-body font-medium text-center fixed bottom-36 text-base md:text-xl ${screen ? 'text-black' : 'text-white'}`}>
                    Scan it, eat it, love it!
                </p>
                <h1 className={`${screen ? 'text-black' : 'text-white'} fixed bottom-24 text-xl text-center flex items-center font-body`}>
                    <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                    Saturday
                </h1>
            </motion.div>

            {/* Overlay to close menu when clicking outside */}
            {isOpen && (
                <div
                    onClick={() => setIsOpen(false)}
                    className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
                ></div>
            )}
        </header>
    );
}

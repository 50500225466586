import React from 'react';
import { motion } from 'framer-motion';
import { image } from '../../assets/image';
import Header from '../../component/Header';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import TestimonialsCarousel from '../constant/Testimonial';
import ContactForm from '../constant/Contact';
import Marquee from 'react-fast-marquee';
import Benefits from '../constant/Benifits';
import Footer from '../../component/Footer';

export default function Home() {
    const images = [image.sample1, image.sample2, image.sample3, image.sample4];

    return (
        <>
            {/* Section 1 */}
            <Header />
            <div className="min-h-screen w-screen bg-black flex pb-5 flex-col-reverse font-body lg:flex-row justify-around items-center px-4 md:px-0">
                {/* Text Content */}
                <div className="text-white flex flex-col space-y-8 items-start md:px-10 justify-center w-full lg:w-1/2">
                    <motion.h1
                        className="hidden md:flex lg:flex md:text-2xl lg:text-6xl font-semibold text-left lg:text-left font-body text-white mt-8 md:mt-10"
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 10,
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.5,
                        }}
                    >
                        Payment solutions <br /> designed for the <br /> world's best <br /> restaurants
                    </motion.h1>
                    <motion.h1
                        className="text-3xl md:hidden lg:hidden w-full text-justify lg:text-left font-body text-white"
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 10,
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.5,
                        }}
                    >
                        Payment solutions <br /> designed for the world's <br />  best restaurants

                    </motion.h1>
                    <p className='font-body font-normal text-base md:text-xl text-gray-300'>
                        All the best restaurants, from quick service to Michelin star, <br /> choose to take payments with Saturday.
                    </p>
                    <button className="px-4 py-3 bg-white hover:scale-125 text-black font-semibold font-body rounded-full hover:bg-logo hover:text-white transition">
                        Get Started
                    </button>
                </div>

                {/* Carousel Section */}
                <div className=" w-full lg:w-[600px] mt-24  lg:mt-0">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showIndicators={false}
                        showArrows={false}
                        interval={5000}
                    >
                        {images.map((item, index) => (
                            <img key={index} className="h-full w-full object-contain" src={item} alt={`carousel-image-${index}`} />
                        ))}
                    </Carousel>
                </div>
            </div>
            <TestimonialsCarousel />
            <ContactForm />
            <Marquee
                className='w-auto py-4'
                direction='right'
            >
                <img src={image.chai} alt='chai' className='w-[100px] h-[100px] object-contain' />
            </Marquee>
            <Benefits />
            <Footer />
        </>
    );
}

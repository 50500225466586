export const image = {
    logo: require('./images/squiggle.png'),
    sample1: require('./images/sample1.png'),
    sample2: require('./images/sample2.png'),
    sample3: require('./images/sample3.png'),
    sample4: require('./images/sample4.png'),
    culture: require('./images/culture.jpg'),
    last: require('./images/last.png'),
    reviews: require('./images/reviews.png'),
    guest: require('./images/guest.png'),
    staff: require('./images/staff.png'),
    tips: require('./images/tips.png'),
    turnover: require('./images/turnover.png'),
    cost: require('./images/cost.png'),
    op: require('./images/frame.png'),


    // Brands
    chai: require('./images/Brand/chai.png'),

}
import React from 'react'
import Home from './pages/home/Home'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/merchant/auth/Login'
import Forgot from './pages/merchant/auth/Forgot'
import Sent from './pages/merchant/auth/Sent'
import Notfound from './pages/constant/Notfound'
import WhoWeAre from './pages/home/Who'
import OrderPay from './pages/home/OrderPay'
import PayTable from './pages/home/PayTable'
import Demo from './pages/home/Demo'
import CustomerHome from './pages/customer/CustomerHome'
import Menu from './pages/customer/Menu'
import Cart from './pages/customer/Cart'
import ItemInfo from './pages/customer/ItemInfo'

export default function App() {
  return (
    <Routes>
      {/* Normal Routes */}
      <Route path='/' element={<Home />} />
      <Route path='/who-we-are' element={<WhoWeAre />} />
      <Route path='/order-and-pay' element={<OrderPay />} />
      <Route path='/pay-at-table' element={<PayTable />} />
      <Route path='/get-started' element={<Demo />} />

      {/* Customer Routes */}
      <Route path='/restaurant' element={<CustomerHome />} />
      <Route path='/menu' element={<Menu />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/product-info' element={<ItemInfo />} />

      {/* Merchant Routes */}
      <Route path='/merchant/login' element={<Login />} />
      <Route path='/merchant/reset-password' element={<Forgot />} />
      <Route path='/merchant/reset-link' element={<Sent />} />

      {/* 404 */}
      <Route path='/*' element={<Notfound />} />

    </Routes>
  )
}
